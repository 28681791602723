<template>
  <div class="actvitity-overview">

    <v-container>
      <v-breadcrumbs :items="items"></v-breadcrumbs>

      <h1 class="font-weight-light">
        Activity Overview
      <v-chip
        class="ma-2"
        color="amber"
        label
      >
        Admin View Only
      </v-chip>
      </h1>

      <v-snackbar v-model="error">
        <v-icon>mdi-alert-circle-outline</v-icon>
        {{ this.message }}
      </v-snackbar>

      <div v-if="loading">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>

      <v-row v-if="!loading">
        <v-col cols="12" xs="6" sm="6" md="4" lg="3" xl="2" v-for="(client, i) in clients" :key="i">
          <v-hover v-slot="{ hover }">
            <v-card
              link
              @click="setClient(client.name, client.id)"
              :elevation="hover ? 12 : 2"
              :class="{ 'on-hover': hover }"
            >
              <div class="client-header">
                <div class="client-header-img">
                  <v-img
                    :src="clientLogo(client.name)"
                    @error="imageError = true"
                    max-height="150"
                    max-width="150"
                  ></v-img>
                </div>
              </div>
              <v-card-title>{{client.name}}</v-card-title>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
    </v-container>

  </div>
</template>

<script>
import getClients from '@/services/getClients';
// import clientData from '@/services/clients.json';
import { keySort } from '@/services/sort';

export default {
  name: 'ActivityOverview',
  metaInfo: {
    title: 'Activity',
  },
  data() {
    return {
      clients: [], // clientData,
      loading: false,
      imageError: false,
      defaultImage: require('@/assets/clients/Terrene_logo.png'), // eslint-disable-line
      error: false,
      message: '',
      items: [
        {
          text: 'AppHub',
          disabled: false,
          to: '/',
        },
        {
          text: 'Activity',
          disabled: true,
          to: 'activity',
        },
      ],
    };
  },
  methods: {
    getClients() {
      this.loading = true;
      getClients()
        .then((results) => {
          const sortResults = results.sort(keySort('name'));
          const removedResults = [
            'AF Group',
            'AgencyKPI',
            'Allianz',
            'Argo',
            'Ascot Demo',
            'CNA-backup-20200629',
            'CSH PenTest',
            'Duck Creek',
            'External Demo',
            'Think Design',
            'Insureon',
            'NSM',
            'NTT Data',
            'PwC',
            'Salesforce',
            'West Bend-old',
            'Texas Mutual',
            'SAIF',
            'SCIF',
            'NIIT',
            'RLI',
            'Hallmark',
            'GEICO',
            'NJM',
            'Salesforce',
          ];
          // item => !values.includes(item)
          const filterResults = sortResults.filter((e) => !removedResults.includes(e.name));
          // ((e) => e.name !== 'AF Group');
          this.clients = filterResults;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error); // eslint-disable-line
          this.error = true;
          this.message = error.message;
        });
    },
    setClient(clientName, clientAccountId) {
      this.$store.dispatch('client', clientName);
      this.$store.dispatch('accountId', clientAccountId);
      this.$router.push({ path: `/activity/${clientName}` });
    },
    clientLogo(client) {
      return this.imageError ? this.defaultImage : require(`@/assets/clients/${client}_logo.png`); // eslint-disable-line
    },
  },
  mounted() {
    this.getClients();
  },
};
</script>

<style scoped>
  .client-header {
    width: 100%;
    height: 200px;
    padding: 1rem;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .card-text {
    background: rgba(0,0,0,0.05);
  }
</style>
